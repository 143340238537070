import { Box, Divider, Grid2 as Grid, Typography } from '@mui/material';
import useFirebaseCollection from '../../hooks/useFirebaseCollection';
import TutorCard from './TutorCard';
import { Tutor } from '../../types/Tutor';

export default function TutorsList() {
  const { data } = useFirebaseCollection<Tutor>('Tutors');

  return (
    <Box
      sx={{
        margin: { xs: 0, lg: '0 300px' },
        padding: 10,
        textAlign: 'center',
      }}
    >
      <Box>
        <Typography variant='h4'>Our Tutors</Typography>
        <Divider style={{ width: 80, margin: '5px auto 20px auto' }} />
      </Box>
      <Grid
        container
        spacing={2}
      >
        {data?.map((tutor) => (
          <Grid
            size={{ xs: 12, md: 6, lg: 4, xl: 3 }}
            sx={{ display: 'flex', justifyContent: 'center' }}
            key={tutor.id}
          >
            <TutorCard tutor={tutor} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
