import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Link } from 'react-router-dom';

export default function NavDrawer({
  drawerState,
}: {
  drawerState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}) {
  const [drawerOpen, setDrawerOpen] = drawerState;

  return (
    <Drawer
      anchor='left'
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Box sx={{ width: 250 }}>
        <List subheader={<ListSubheader component='div'>Pages</ListSubheader>}>
          <ListItem
            disablePadding
            component={Link}
            to='/tutors'
            onClick={() => setDrawerOpen(false)}
            sx={{ color: 'inherit' }}
          >
            <ListItemButton>
              <ListItemText primary='Tutors' />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to='/testimonials'
            onClick={() => setDrawerOpen(false)}
            sx={{ color: 'inherit' }}
          >
            <ListItemButton>
              <ListItemText primary='Testimonials' />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to='/pricing'
            onClick={() => setDrawerOpen(false)}
            sx={{ color: 'inherit' }}
          >
            <ListItemButton>
              <ListItemText primary='Pricing' />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to='/contact-us'
            onClick={() => setDrawerOpen(false)}
            sx={{ color: 'inherit' }}
          >
            <ListItemButton>
              <ListItemText primary='Contact Us' />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List
          subheader={<ListSubheader component='div'>Socials</ListSubheader>}
        >
          <ListItem disablePadding>
            <ListItemButton
              href='https://www.instagram.com/bloomed.acad/'
              target='_blank'
              onClick={() => setDrawerOpen(false)}
            >
              <ListItemText primary='Instagram' />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              href='https://www.facebook.com/bloomedx'
              target='_blank'
              onClick={() => setDrawerOpen(false)}
            >
              <ListItemText primary='Facebook' />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
