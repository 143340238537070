import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDZnvLC6e7POWtyxctO_iZZi69zjejZHBw',
  authDomain: 'bloomed-d1f3c.firebaseapp.com',
  projectId: 'bloomed-d1f3c',
  storageBucket: 'bloomed-d1f3c.firebasestorage.app',
  messagingSenderId: '580595619951',
  appId: '1:580595619951:web:494cc47cf170c0a7c0559a',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
