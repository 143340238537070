import { Box, Grid2 as Grid, Typography, Divider } from '@mui/material';
import maryamNour from '../../assets/images/maryam-nour.png';

export default function TutorJumbotron() {
  return (
    <Box
      sx={{ margin: { lg: '0 300px', xs: 0 } }}
      color='white'
    >
      <Grid
        container
        spacing={4}
      >
        <Grid
          size={{ xs: 12, xl: 'auto' }}
          textAlign='center'
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <img
              src={maryamNour}
              style={{
                borderRadius: '50%',
                height: 150,
                width: 150,
              }}
              alt='Bloomed CEO Portrait'
            />
            <br />
            <Typography fontWeight='bold'>Maryam Nour</Typography>
            <Typography>CEO of Bl∞med Alliance for Medicine</Typography>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, xl: 'grow' }}>
          <Typography
            variant='h2'
            fontWeight='bold'
          >
            Welcome Message
          </Typography>
          <Divider style={{ borderColor: 'white', marginTop: 5 }} />
          <br />
          <Typography>
            Hello everyone,
            <br />
            <br />
            What started as Bl∞med, LLC—a scrub line rooted in a passion for
            honoring healthcare heroes—has evolved into something far greater. I
            am deeply honored to contribute to the medical community through
            Bl∞med Alliance for Medicine. Originally inspired by a beloved blue
            parakeet named Blooberry, whose memory continues to inspire our
            mission, Bl∞med Alliance for Medicine is now a specialized platform
            and extension of the company. Our focus is dedicated to supporting
            students at every stage of their medical journey, from their first
            semester of medical school through residency and beyond.
            <br />
            <br />
            Our team brings extensive experience in mentoring and tutoring for
            standardized U.S. medical examinations. Comprised of skilled medical
            students, physicians, and residents, they are deeply committed to
            guiding others toward success.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
