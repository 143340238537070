import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { Package } from '../../types/Package';

// eslint-disable-next-line
const pattern = /^\$(\d{1,3}(?:,\d{3})*)(\/[\w\d\s\/]+)?$/;

export default function PricingCard({
  package: pricing,
}: {
  package: Package;
}) {
  const match = pricing.rate.match(pattern);

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: { xs: '100%', sm: 250 },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      variant='outlined'
    >
      <CardContent>
        <Typography variant='button'>{pricing.package}</Typography>
        <br />
        <br />
        {pricing.description && (
          <Typography
            variant='body2'
            component='div'
            dangerouslySetInnerHTML={{ __html: pricing.description }}
          />
        )}
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          size='small'
          href={pricing.stripe_link}
          target='_blank'
        >
          Purchase
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Typography
            fontWeight='bold'
            fontSize={20}
          >
            ${match?.[1]}
          </Typography>
          <Typography variant='caption'>{match?.[2] || ''}</Typography>
        </Box>
      </CardActions>
    </Card>
  );
}
