import { Box, Divider, Typography } from '@mui/material';

export default function PageHeader({ title }: { title: string }) {
  return (
    <Box textAlign='center'>
      <Typography
        variant='h3'
        fontWeight='bold'
      >
        {title}
      </Typography>
      <Divider
        style={{
          width: 80,
          margin: '5px auto 20px auto',
          borderColor: 'black',
        }}
      />
    </Box>
  );
}
