import emailjs from '@emailjs/browser';

export default function useEmailJs() {
  async function sendEmail(formElement: HTMLFormElement) {
    return emailjs.sendForm(
      'service_z97rgpf',
      'template_dcytujj',
      formElement,
      {
        limitRate: { id: 'app', throttle: 10000 },
        publicKey: 'KHIgFtr5GeDwLuK8Y',
      }
    );
  }

  return { sendEmail };
}
