import { Avatar, Box, Divider, Paper, Typography } from '@mui/material';
import { Testimonial } from '../../types/Testimonial';
import styled from 'styled-components';

export default function TestimonialsCard({
  testimonial,
}: {
  testimonial: Testimonial;
}) {
  return (
    <Paper variant='outlined'>
      <Content>
        <Box
          display='flex'
          alignItems='center'
          padding={1}
          mr={1}
        >
          <Avatar sx={{ height: 100, width: 100, fontSize: 50 }}>
            {testimonial.name?.[0] || 'A'}
          </Avatar>
        </Box>
        <Review>
          <Typography fontStyle='italic'>"{testimonial.review}"</Typography>
          <Box>
            <Divider />
            <NameAndDateContainer>
              <Typography fontWeight='bold'>{testimonial.name}</Typography>
              <Typography variant='caption'>
                {testimonial.date.toDate().toLocaleDateString()}
              </Typography>
            </NameAndDateContainer>
          </Box>
        </Review>
      </Content>
    </Paper>
  );
}

const Content = styled.div`
  display: flex;
  padding: 10px;
  min-height: 150px;
`;

const Review = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const NameAndDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
