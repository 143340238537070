import { Box, Grid2 as Grid } from '@mui/material';
import PageHeader from '../common/PageHeader';
import useFirebaseCollection from '../../hooks/useFirebaseCollection';
import { Testimonial } from '../../types/Testimonial';
import TestimonialsCard from './TestimonialsCard';
import { useMemo } from 'react';

export default function Testimonials() {
  const { data } = useFirebaseCollection<Testimonial>('Testimonials');

  const sortedData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const copy = [...data];
    copy.sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime());

    return copy;
  }, [data]);

  return (
    <Box sx={{ padding: 2, margin: { xs: 0, lg: '0 300px' } }}>
      <PageHeader title='Testimonials' />
      <br />
      <Grid
        container
        spacing={2}
      >
        {sortedData?.map((testimonial) => (
          <Grid
            key={testimonial.id}
            size={{ xs: 12 }}
          >
            <TestimonialsCard testimonial={testimonial} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
