import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from '@mui/material';
import { Tutor } from '../../types/Tutor';

export default function TutorCard({ tutor }: { tutor: Tutor }) {
  return (
    <Card
      style={{ width: 300, height: '100%' }}
      variant='outlined'
    >
      <CardMedia
        component='img'
        image={tutor.picture}
        alt='Tutor Portrait'
        style={{
          height: 200,
          width: 'auto',
          borderRadius: '50%',
          margin: 'auto',
          padding: 5,
          maxWidth: 150,
        }}
      />
      <CardContent
        sx={{
          maxWidth: 200,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          margin: 'auto',
        }}
      >
        <Tooltip title={tutor.fullName}>
          <Typography
            sx={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              margin: 'auto',
            }}
          >
            {tutor.fullName}
          </Typography>
        </Tooltip>
        <Box sx={{ mt: 1 }} />
        <Tooltip title={tutor.title}>
          <Typography variant='caption'>{tutor.title}</Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
