import { Box, useTheme } from '@mui/material';
import TutorJumbotron from './TutorJumbotron';
import TutorsList from './TutorsList';

export default function Tutors() {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          padding: 5,
        }}
      >
        <TutorJumbotron />
      </Box>
      <TutorsList />
    </>
  );
}
