import {
  collection,
  getDocs,
  query,
  QueryFieldFilterConstraint,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../config/Firebase';

export default function useFirebaseCollection<T>(
  collectionName: string,
  customQuery?: QueryFieldFilterConstraint
) {
  const [data, setData] = useState<T[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const collectionRef = collection(db, collectionName);

        let q;

        if (customQuery) {
          q = query(collectionRef, customQuery);
        }

        const querySnapshot = await getDocs(q ? q : collectionRef);

        const docs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setData(docs as T[]);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [collectionName, customQuery]);

  return { data, loading };
}
