import { useEffect, useMemo } from 'react';
import useFirebaseCollection from '../../hooks/useFirebaseCollection';
import { Package } from '../../types/Package';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import PricingCard from './PricingCard';
import PageHeader from '../common/PageHeader';
import { DisplayOrder } from '../../types/DisplayOrder';
import { where } from 'firebase/firestore';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

type SortedGroupsType = { [key: string]: Package[] };

export default function Pricing() {
  const location = useLocation();
  const { data } = useFirebaseCollection<Package>('Packages');

  const { data: displayOrder } = useFirebaseCollection<DisplayOrder>(
    'DisplayOrder',
    useMemo(() => where('collection', '==', 'Packages'), [])
  );

  const groupedPackages = useMemo(() => {
    let sortedData = data?.reduce((grouped, pkg) => {
      // If the group doesn't exist yet, initialize it as an empty array
      if (!grouped[pkg.group]) {
        grouped[pkg.group] = [];
      }
      // Push the package into the appropriate group
      grouped[pkg.group].push(pkg);
      return grouped;
    }, {} as SortedGroupsType);

    if (
      displayOrder?.length &&
      sortedData &&
      Object.entries(sortedData).length
    ) {
      const { sortOrder } = displayOrder[0];

      const sortedObj = sortOrder.reduce((result, key) => {
        if (sortedData?.[key]) {
          result[key] = sortedData[key]; // Add the key and its value to the result if it exists in `obj`
        }
        return result;
      }, {} as SortedGroupsType);

      for (let key in sortedData) {
        if (!displayOrder.includes(key as any)) {
          sortedObj[key] = sortedData?.[key];
        }
      }

      return sortedObj;
    }

    return sortedData;
  }, [data, displayOrder]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const viewPackage = queryParams.get('viewPackage');

    if (
      groupedPackages &&
      Object.entries(groupedPackages).length &&
      viewPackage
    ) {
      document.getElementById(viewPackage)?.scrollIntoView();
    }
  }, [groupedPackages, location]);

  return (
    <Box sx={{ padding: 2, margin: { xs: 0, lg: '0 300px' } }}>
      <PageHeader title='Plans & Pricing' />
      {groupedPackages &&
        Object.entries(groupedPackages).length &&
        Object.entries(groupedPackages).map(([title, packages]) => {
          const packagesSorted = [...packages];

          packagesSorted.sort((a, b) => {
            if (a.package > b.package) return 1; // a should come after b
            if (a.package < b.package) return -1; // a should come before b
            return 0; // they are equal
          });

          return (
            <div
              id={_.kebabCase(title)}
              key={title}
            >
              <Typography
                variant='h5'
                fontWeight='bold'
                sx={{ textAlign: { xs: 'center', sm: 'start' } }}
              >
                {title}
              </Typography>
              <br />
              <Grid
                container
                spacing={2}
              >
                {packagesSorted.map((p) => (
                  <Grid
                    size={{ xs: 12, sm: 4, md: 3 }}
                    key={p.id}
                    sx={{
                      display: { xs: 'flex', sm: 'block' },
                      justifyContent: { xs: 'center', sm: 'undefined' },
                    }}
                  >
                    <PricingCard package={p} />
                  </Grid>
                ))}
              </Grid>
              <br />
              <br />
            </div>
          );
        })}
    </Box>
  );
}
