import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import logo from '../../assets/images/logo.png';
import { Facebook, Instagram, Menu } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import NavDrawer from './NavDrawer';

export default function NavBar() {
  const drawerState = useState(false);

  const [, setDrawerOpen] = drawerState;

  return (
    <header>
      <AppBar
        position='sticky'
        color='inherit'
        elevation={0}
        variant='outlined'
      >
        <Toolbar
          disableGutters
          sx={{
            padding: '0 20px',
            margin: { xs: 0, lg: '0 300px' },
            justifyContent: { xs: 'center', lg: undefined },
          }}
        >
          <IconButton
            sx={{ display: { xs: 'block', lg: 'none' } }}
            onClick={() => setDrawerOpen(true)}
          >
            <Menu />
          </IconButton>
          <Box
            sx={{
              flex: { xs: 1, lg: 0 },
              justifyContent: { xs: 'center', lg: undefined },
              display: { xs: 'flex', lg: undefined },
            }}
          >
            <Link to='/'>
              <img
                src={logo}
                alt='Bloomed logo'
                height={100}
              />
            </Link>
          </Box>
          <Box sx={{ flex: 1, ml: 5, display: { xs: 'none', lg: 'flex' } }}>
            <Link to='/tutors'>
              <Button>Tutors</Button>
            </Link>
            <Link to='/testimonials'>
              <Button>Testimonials</Button>
            </Link>
            <Link to='/pricing'>
              <Button>Pricing</Button>
            </Link>
            <Link to='/contact-us'>
              <Button>Contact Us</Button>
            </Link>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <IconButton
              href='https://www.instagram.com/bloomed.acad/'
              target='_blank'
            >
              <Instagram />
            </IconButton>
            <IconButton
              href='https://www.facebook.com/bloomedx'
              target='_blank'
            >
              <Facebook />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <NavDrawer drawerState={drawerState} />
    </header>
  );
}
